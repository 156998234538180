const OSS_URL = 'https://mankind.oss-cn-guangzhou.aliyuncs.com/'
const development = process.env.VUE_APP_ENV === 'development'
export const baseURL = development ? '/test' : process.env.VUE_APP_BASE_API
const SOURCE = 'web'

export const ImageOrigin = development ? `http://second-server.yinyuancanyin.com:30080/download/` : process.env.VUE_APP_FILE_API
export const LogoImage = process.env.VUE_APP_DEFAULT_LOGO

export const GainschaFonts = [
  {
    value: '1',
    label: '英数字体 8x12',
    font: '1',
    style: {
      fontSize: 12
    }
  },
  {
    value: '2',
    label: '英数字体 12x20',
    font: '2',
    style: {
      fontSize: 14
    }
  },
  {
    value: '3',
    label: '英数字体 16x24',
    font: '3',
    style: {
      fontSize: 16
    }
  },
  {
    value: '4',
    label: '英数字体 24x32',
    font: '4',
    style: {
      fontSize: 24
    }
  },
  {
    value: '5',
    label: '英数字体 32x48',
    font: '5',
    style: {
      fontSize: 32
    }
  },
  {
    value: 'TSS16.BF2',
    label: '简体中文 16×16',
    font: 'TSS16.BF2',
    style: {
      fontSize: 16
    }
  },
  {
    value: 'TSS20.BF2',
    label: '简体中文 20×20',
    font: 'TSS20.BF2',
    style: {
      fontSize: 20
    }
  },
  {
    value: 'TSS24.BF2',
    label: '简体中文 24×24',
    font: 'TSS24.BF2',
    style: {
      fontSize: 24
    }
  },
  {
    value: 'TSS32.BF2',
    label: '简体中文 32×32',
    font: 'TSS32.BF2',
    style: {
      fontSize: 32
    }
  },
  {
    value: 'TST24.BF2',
    label: '繁体中文 24×24',
    font: 'TST24.BF2',
    style: {
      fontSize: 24
    }
  }
]
export const DayjsFormatters = [
  {
    formatter: 'YY',
    res: '18',
    desc: '年，两位数'
  },
  {
    formatter: 'YYYY',
    res: '2018',
    desc: '年，四位数'
  },
  {
    formatter: 'M',
    res: '1-12',
    desc: '月，从1开始'
  },
  {
    formatter: 'MM',
    res: '01-12',
    desc: '月，两位数'
  },
  {
    formatter: 'MMM',
    res: 'Jan-Dec',
    desc: '月，英文缩写'
  },
  {
    formatter: 'MMMM',
    res: 'January-December',
    desc: '月，英文全称'
  },
  {
    formatter: 'D',
    res: '1-31',
    desc: '日'
  },
  {
    formatter: 'DD',
    res: '01-31',
    desc: '日，两位数'
  },
  {
    formatter: 'd',
    res: '0-6',
    desc: '一周中的一天，星期天是 0'
  },
  {
    formatter: 'dd',
    res: 'Su-Sa',
    desc: '最简写的星期几'
  },
  {
    formatter: 'ddd',
    res: 'Sun-Sat',
    desc: '简写的星期几'
  },
  {
    formatter: 'dddd',
    res: 'Sunday-Saturday',
    desc: '星期几，英文全称'
  },
  {
    formatter: 'H',
    res: '0-23',
    desc: '小时'
  },
  {
    formatter: 'HH',
    res: '00-23',
    desc: '小时，两位数'
  },
  {
    formatter: 'h',
    res: '1-12',
    desc: '小时, 12 小时制'
  },
  {
    formatter: 'hh',
    res: '01-12',
    desc: '小时, 12 小时制, 两位数'
  },
  {
    formatter: 'm',
    res: '0-59',
    desc: '分钟'
  },
  {
    formatter: 'mm',
    res: '00-59',
    desc: '分钟，两位数'
  },
  {
    formatter: 's',
    res: '0-59',
    desc: '秒'
  },
  {
    formatter: 'ss',
    res: '00-59',
    desc: '秒，两位数'
  },
  {
    formatter: 'S',
    res: '0-9',
    desc: '毫秒（十），一位数'
  },
  {
    formatter: 'SS',
    res: '00-99',
    desc: '毫秒（百），两位数'
  },
  {
    formatter: 'SSS',
    res: '000-999',
    desc: '毫秒，三位数'
  },
  {
    formatter: 'Z',
    res: '-05:00',
    desc: 'UTC 的偏移量，±HH:mm'
  },
  {
    formatter: 'ZZ',
    res: '-0500',
    desc: 'UTC 的偏移量，±HHmm'
  },
  {
    formatter: 'A',
    res: 'AM / PM',
    desc: '上/下午，大写'
  },
  {
    formatter: 'a',
    res: 'am / pm',
    desc: '上/下午，小写'
  },
  {
    formatter: 'Do',
    res: '1st... 31st',
    desc: '月份的日期与序号'
  }
]

export { OSS_URL, SOURCE }
